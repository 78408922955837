<template>
	<div>
		<DataTable
			v-if="show"
			:getQuery="queries.get"
			:createQuery="queries.create"
			:removeQuery="queries.remove"
			:updateQuery="queries.update"
			:headers="headers"
			:createFields="createFields"
			:connector="connector"
			:conditions="conditions"
			icon="mdi-tag"
			result_name="depots"
			title="depot"
			subtitle="List of all depots"
		>
		</DataTable>
	</div>
</template>
<script>
import DataTable from '@/components/datatable'
import {gql} from 'apollo-boost'
export default {
	components: {DataTable},
    data(){
		return {
			show: false,
			queries: require('../../../../legacy/unwanted_gql/depot.gql'),
			user: this.$store.getters['auth/getUserData'],
			conditions: {},
			connector: {},
			createFields:[
				{name:'name', label: 'Name', icon: 'mdi-account'},
				{type: 'tel', name:'phone', label: 'Phone', icon: 'mdi-phone'},
				{name:'location', label: 'Location', icon: 'mdi-map-marker'},
			],
			headers: [
				{ text: 'Name', align: 'left', value: 'name' },
				{ text: 'Phone No.', align: 'left', value: 'phone' },
				{ text: 'Location.', align: 'left', value: 'location' },
				{ text: 'Date Added', align: 'left', value: 'createdAt' },
				{ text: 'Actions', value: 'action', sortable: false },
			],
		}
	},
	created(){
		const company_id = this.user.company.id
		this.conditions = {
			company: {
				id: company_id
			}
		}
		this.connector = {
			company: {
				connect: {
					id: company_id
				}
			}
		}
		this.show = true
	}	
}
</script>
